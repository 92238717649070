<template>
	<v-main class="pagePg">
		<v-fade-transition mode="out-in">
			<router-view />
		</v-fade-transition>
	</v-main>
</template>

<script>
export default {
	name: "BaseView",
};
</script>

<style>
.theme--light .pagePg {
	background: #eeeeee;
}
</style>